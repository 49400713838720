<template>
  <notifications :duration="8000">
    <template #body="{ item, close }">
      <div
        class="notification-bar"
        :data-test="`notification-bar-${item.type}`"
        :class="`notification-bar--${item.type}`"
      >
        <span class="notification-bar__icon">
          <IconComponent :name="getNotificationIcon(item)" />
        </span>
        <p class="notification-bar__title">
          {{ item.title }}
        </p>
        <button
          class="notification-bar__close"
          type="button"
          @click="close"
        >
          <IconComponent name="cross" />
        </button>
      </div>
    </template>
  </notifications>
</template>

<script src="./notificationBar.js"></script>

<style lang="scss">
  // forcing !important because of vue-notification library
  .vue-notification-group {
    z-index: map-get($zindex, notificationBar) !important;
    top: 8rem !important;
    right: 1rem !important;
    @include media-breakpoint-up(lg) {
      top: 15rem !important;
      width: fit-content !important;
    }
  }
  .notification-bar {
    border-radius: 5rem;
    margin-bottom: .5rem;
    background: $neutral-0;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;

     &__close .icon-component {
      width: auto;

      svg {
        width: 1rem;
        fill: $neutral-60;
      }
    }

    &__title {
      margin: 0 1rem;
      padding-bottom: 0;
      flex-grow: 1;
      white-space: pre-wrap;
    }

    &.notification-bar--error {
      border: 1px solid $critical-60;

      .notification-bar__icon svg {
        fill: $critical-60;
      }

    }

    &.notification-bar--success {
      border: 1px solid $success-60;

      .notification-bar__icon svg {
        fill: $success-60;
      }
    }
  }
</style>
