// Map form error codes from response to translated messages
import i18n from '@/i18n';

const formErrorMapping = {
  // NON FIELD ERROR CODES
  care_plan_already_exists: 'general.formGeneralError.carePlanAlreadyExists',
  care_taker_email_same_as_care_giver_email: 'general.formGeneralError.careTakerEmailAsCareGiver',
  default: 'general.formGeneralError.default',
  email_already_exist: 'general.formGeneralError.emailAlreadyExist',
  enrollment_organisation_restrict_inviting_patients:
    'general.formGeneralError.enrollmentOrganisationRestrictInvitingPatients',
  hidden_step_cannot_be_important: 'general.formGeneralError.hiddenStepCannotBeImportant',
  important_step_cannot_be_hidden: 'general.formGeneralError.importantStepCannotBeHidden',
  incorrect_email_password: 'general.formGeneralError.incorrectEmailPassword',
  invalid_email: 'general.formGeneralError.invalidEmail',
  invalid_old_password: 'general.formGeneralError.invalidOldPassword',
  invalid_password: 'general.formGeneralError.invalidPassword',
  non_existent_treatment: 'general.formGeneralError.nonExistentTreatment',
  not_possible_to_send_this_invitation: 'general.formGeneralError.notPossibleToSendThisInvitation',
  organisation_already_exists: 'general.formGeneralError.organisationAlreadyExists',
  participant_with_email_already_exists: 'general.formGeneralError.participantWithEmailAlreadyExists',
  professional_already_exists: 'general.formGeneralError.professionalAlreadyExists',
  registered_user_already_exists: 'general.formGeneralError.registeredUserAlreadyExists',
  step_with_message_cannot_be_hidden: 'general.formGeneralError.stepWithMessagesCannotBeHidden',
  treatment_assignment_already_exists: 'general.formGeneralError.treatmentAssignmentAlreadyExists',
  unaccepted_privacy_agreement: 'general.formGeneralError.unacceptedPrivacyAgreement',
  visited_step_cannot_be_hidden: 'general.formGeneralError.visitedStepCannotBeHidden',
  wsd_time_start: 'components.widgetVideoCall.errorTimeStart',

  // FIELD ERROR CODES
  invalid: 'general.formGroupError.default',
  required: 'general.formGroupError.required',
  blank: 'general.formGroupError.blank',
};

const translate = (key, optionalKey) => (
  i18n.global.t(formErrorMapping[key] || optionalKey)
);

const getErrorTranslationFromKey = (errorData) => {
  let errors = [];
  const keys = [];
  const isValid = typeof errorData === 'object' && errorData !== null;
  const setDefaultError = () => errors.push(`${translate('default')}`);

  if (!isValid) {
    // Return a default error if errorData is not a valid object
    setDefaultError();
    return errors;
  }

  const getErrorsStrings = (codes = {}) => {
    // Recursive function to read deeply error objects
    // append string errors '...keys: value' into the error list
    Object.keys(codes).forEach((key) => {
      const isObject = typeof codes[key] === 'object' && codes[key] !== null;
      const isArray = Array.isArray(codes[key]);

      if (isArray) {
        const error = `${keys.join(' ')} ${key}: ${translate(codes[key][0], formErrorMapping.invalid)}`;
        errors = [...errors, error];
      }

      if (isObject && !isArray) {
        keys.push(key);
        getErrorsStrings(codes[key]);
        keys.splice(-1, 1);
      }
    });
  };

  if (errorData?.non_field_errors) {
    // Append, at the beginning of the error list, the non_field_error
    errors = [`${translate(errorData?.non_field_errors, formErrorMapping.default)}`, ...errors];
  } else getErrorsStrings(errorData);

  // Append a default error if the error object contains corrupted data
  if (!errors.length) setDefaultError();
  return errors;
};

export default getErrorTranslationFromKey;
