/* eslint-disable max-len */
import { createWebHistory, createRouter } from 'vue-router';

import store from '@/store/store';
import roles from '@/constants/roles';

import PageInternalError from '@/views/errors/500/500View.vue';
import PageNotFound from '@/views/errors/404/404View.vue';
import PageRestricted from '@/views/errors/403/403View.vue';

import routes from './routes';

const ActivateAccount = () => import('@/views/auth/activate/activateAccount/ActivateAccount.vue');
const ActivateAccountPrompt = () => import('@/views/auth/activate/activateAccountPrompt/ActivateAccountPrompt.vue');
const CarePlanAcceptInvitations = () => import('@/views/careplan/carePlanAcceptInvitations/CarePlanAcceptInvitations.vue');
const CarePlanAdd = () => import('@/views/careplan/carePlanAdd/CarePlanAdd.vue');
const CarePlanCreate = () => import('@/views/careplan/carePlanCreate/CarePlanCreate.vue');
const CarePlanCreateNoPermission = () => import('@/views/careplan/carePlanCreate/carePlanCreateNoPermission/CarePlanCreateNoPermission.vue');
const CarePlanCreateWithoutEmail = () => import('@/views/careplan/carePlanCreate/carePlanCreateWithoutEmail/CarePlanCreateWithoutEmail.vue');
const CarePlanParticipant = () => import('@/views/careplan/carePlanParticipant/CarePlanParticipant.vue');
const ChangeEmailSuccess = () => import('@/views/users/changeEmailSuccess/ChangeEmailSuccess.vue');
const ChangePassword = () => import('@/views/auth/resetPassword/changePassword/ChangePassword.vue');
const ChatView = () => import('@/views/chat/ChatView.vue');
const CoachingView = () => import('@/views/coaching/CoachingView.vue');
const CreateCarePlanUnderOrganisation = () => import('@/views/auth/createCarePlanUnderOrganisation/CreateCarePlanUnderOrganisation.vue');
const DashboardView = () => import('@/views/dashboard/DashboardView.vue');
const DataStorageAgreement = () => import('@/views/auth/dataStorageAgreement/DataStorageAgreement.vue');
const DeleteAccount = () => import('@/views/auth/activate/deleteAccount/DeleteAccount.vue');
const DiaryView = () => import('@/views/diary/DiaryView.vue');
const ExpiredToken = () => import('@/views/auth/expiredToken/ExpiredToken.vue');
const FeedbackAccount = () => import('@/views/auth/activate/feedbackAccount/FeedbackAccount.vue');
const ForgotPassword = () => import('@/views/auth/resetPassword/forgotPassword/ForgotPassword.vue');
const GroupsView = () => import('@/views/groups/GroupsView.vue');
const LoginView = () => import('@/views/auth/login/LoginView.vue');
const LoginWithToken = () => import('@/views/auth/loginWithToken/LoginWithToken.vue');
const MonitorAcceptRequests = () => import('@/views/monitor/monitorAcceptRequests/MonitorAcceptRequests.vue');
const MonitorView = () => import('@/views/monitor/MonitorView.vue');
const PageGeneralError = () => import('@/views/errors/generalError/GeneralError.vue');
const PatientCarePlans = () => import('@/views/careplan/patientCarePlans/PatientCarePlans.vue');
const PatientsView = () => import('@/views/careplan/patients/PatientsView.vue');
const PermissionGrantedKrachtplan = () => import('@/views/auth/activate/permissionGrantedKrachtplan/PermissionGrantedKrachtplan.vue');
const QuestionnaireAssignment = () => import('@/views/questionnaire/questionnaireAssignment/QuestionnaireAssignment.vue');
const QuestionnaireInformant = () => import('@/views/questionnaire/questionnaireInformant/QuestionnaireInformant.vue');
const QuestionnaireList = () => import('@/views/questionnaire/questionnaireList/QuestionnaireList.vue');
const RefinerSurvey = () => import('@/views/refinerSurvey/RefinerSurvey.vue');
const RegisterOptions = () => import('@/views/auth/register/registerOptions/RegisterOptions.vue');
const RegisterSuccess = () => import('@/views/auth/activate/registerSuccess/RegisterSuccess.vue');
const RegisterView = () => import('@/views/auth/register/register/RegisterView.vue');
const RequestOrganisation = () => import('@/views/auth/register/requestOrganisation/RequestOrganisation.vue');
const SecurityCode = () => import('@/views/auth/securityCode/SecurityCode.vue');
const SettingsView = () => import('@/views/users/settings/SettingsView.vue');
const SocialEnvironmentTreatmentStep = () => import('@/views/socialEnvironment/socialEnvironmentTreatmentStep/SocialEnvironmentTreatmentStep.vue');
const SsoValidation = () => import('@/views/auth/ssoValidation/SsoValidation.vue');
const TreatmentAssignmentDetail = () => import('@/views/treatment/treatmentAssignmentDetail/TreatmentAssignmentDetail.vue');
const TreatmentAssignmentStepPayment = () => import('@/views/treatment/treatmentAssignmentStepPayment/TreatmentAssignmentStepPayment.vue');
const TreatmentAssignmentStepPaymentStatus = () => import('@/views/treatment/treatmentAssignmentStepPaymentStatus/TreatmentAssignmentStepPaymentStatus.vue');
const TreatmentAssignmentStep = () => import('@/views/treatment/treatmentAssignmentStep/TreatmentAssignmentStep.vue');
const TreatmentDetail = () => import('@/views/treatment/treatmentDetail/TreatmentDetail.vue');
const TreatmentInformation = () => import('@/views/treatment/treatmentInformation/TreatmentInformation.vue');
const TreatmentList = () => import('@/views/treatment/treatmentList/TreatmentList.vue');
const TreatmentListMultipleOrgs = () => import('@/views/treatment/treatmentListMultipleOrgs/TreatmentListMultipleOrgs.vue');
const TreatmentStep = () => import('@/views/treatment/treatmentStep/TreatmentStep.vue');
const TwoFactorBase = () => import('@/views/auth/twoFactorAuth/TwoFactorAuth.vue');
const UserNameInformation = () => import('@/views/users/userNameInformation/UserNameInformation.vue');

const router = createRouter({
  routes: [
    // URL: /activate
    {
      path: routes('activate'),
      name: routes('activate'),
      component: ActivateAccount,
      meta: {
        title: 'general.titles.activateAccount',
        viewClass: 'view-landing',
      },
    },

    // URL: /activate-prompt
    {
      path: routes('activate_account_prompt'),
      name: routes('activate_account_prompt'),
      component: ActivateAccountPrompt,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.activateAccount',
      },
    },

    // URL: /activate-success
    {
      path: routes('activate_success'),
      name: routes('activate_success'),
      component: RegisterSuccess,
      meta: {
        title: 'general.titles.createdAccount',
        viewClass: 'view-landing',
      },
    },

    // URL: /accept-invitations
    {
      path: routes('care_plan_accept_invitations'),
      name: routes('care_plan_accept_invitations'),
      component: CarePlanAcceptInvitations,
      meta: {
        authRequired: true,
        title: 'general.titles.carePlanAcceptInvitations',
        viewClass: 'view-care-plan-accept-invitations',
      },
    },

    // URL: /accept-monitor-requests
    {
      path: routes('monitor_accept_requests'),
      name: routes('monitor_accept_requests'),
      component: MonitorAcceptRequests,
      meta: {
        authRequired: true,
        title: 'general.titles.monitorAcceptRequests',
        viewClass: 'view-monitor-accept-requests',
        forRoles: [
          roles.PATIENT,
        ],
      },
    },

    // URL: /performance-indicator-survey/:surveyId
    {
      path: routes('performance_indicator_survey'),
      name: routes('performance_indicator_survey'),
      component: RefinerSurvey,
      meta: {
        title: 'general.titles.survey',
      },
      props: (route) => ({
        surveyId: route.params.surveyId,
      }),
    },

    // URL: /permission-success-krachtplan
    {
      path: routes('grant_krachtplan_permission'),
      name: routes('grant_krachtplan_permission'),
      component: PermissionGrantedKrachtplan,
      meta: {
        title: 'general.titles.krachtplanPermission',
        viewClass: 'view-landing',
      },
      props: (route) => ({
        token: route.query.token,
      }),
    },

    // URL: /care-plan-create
    {
      path: routes('care_plan_create'),
      name: routes('care_plan_create'),
      component: CarePlanCreate,
      canReuse: false,
      meta: {
        authRequired: true,
        breadcrumb: {
          name: 'general.titles.patients',
          link: routes('care_plan_participants'),
        },
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.carePlanCreate',
      },
    },

    // URL: /care-plan-create-without-email
    {
      path: routes('care_plan_create_without_email'),
      name: routes('care_plan_create_without_email'),
      component: CarePlanCreateWithoutEmail,
      meta: {
        authRequired: true,
        breadcrumb: {
          name: 'general.titles.patients',
          link: routes('care_plan_participants'),
        },
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        title: 'general.titles.carePlanCreate',
      },
    },

    // URL: /care-plan-create-no-permission
    {
      path: routes('care_plan_create_no_permission'),
      name: routes('care_plan_create_no_permission'),
      component: CarePlanCreateNoPermission,
      meta: {
        navigationBar: true,
        title: 'general.titles.carePlanNoPermission',
      },

    },
    // URL: /patients/:carePlanParticipantId
    {
      path: routes('care_plan_participant'),
      name: routes('care_plan_participant'),
      component: CarePlanParticipant,
      props: (route) => ({
        carePlanParticipantId: parseInt(route.params.carePlanParticipantId, 10),
      }),
      meta: {
        authRequired: true,
        breadcrumb: {
          name: 'general.titles.patients',
          link: routes('care_plan_participants'),
        },
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.patient',
      },
    },

    // URL: /organisation-patients/:carePlanParticipantId
    {
      path: routes('care_plan_participant_admin'),
      name: routes('care_plan_participant_admin'),
      component: CarePlanParticipant,
      props: (route) => ({
        carePlanParticipantId: parseInt(route.params.carePlanParticipantId, 10),
        isOrganisationAdmin: true,
      }),
      meta: {
        authRequired: true,
        breadcrumb: {
          name: 'general.titles.administration',
          link: routes('care_plan_participants_admin'),
        },
        forRoles: [
          roles.ORGANISATION_ADMIN,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.patient',
      },
    },

    // URL: /organisation-patients/:patientId/add-assignment
    {
      path: routes('care_plan_participant_admin_add_assignment'),
      name: routes('care_plan_participant_admin_add_assignment'),
      component: CarePlanAdd,
      canReuse: false,
      props: (route) => ({
        patientId: parseInt(route.params.patientId, 10),
        isOrganisationAdmin: true,
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_ADMIN,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.addMoreToCarePlan',
      },
    },

    // URL: /patients-shared/:carePlanParticipantId
    {
      path: routes('care_plan_participant_shared'),
      name: routes('care_plan_participant_shared'),
      component: CarePlanParticipant,
      props: (route) => ({
        carePlanParticipantId: parseInt(route.params.carePlanParticipantId, 10),
      }),
      meta: {
        authRequired: true,
        breadcrumb: {
          name: 'general.titles.patientShared',
          link: `${routes('care_plan_participants')}?is_shared=true`,
        },
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        title: 'general.titles.patientShared',
      },
    },

    // URL: /patients
    {
      path: routes('care_plan_participants'),
      name: routes('care_plan_participants'),
      component: PatientsView,
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        title: 'general.titles.patients',
      },
    },

    // URL: /organisation-patients
    {
      path: routes('care_plan_participants_admin'),
      name: routes('care_plan_participants_admin'),
      component: PatientsView,
      props: {
        isOrganisationAdmin: true,
      },
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_ADMIN,
        ],
        navigationBar: true,
        title: 'general.titles.administration',
      },
    },

    // URL: /chat
    {
      path: routes('chat'),
      name: routes('chat'),
      component: ChatView,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.chat',
        viewClass: 'chat-view',
      },
    },

    // URL: /coaching
    {
      path: routes('coaching'),
      name: routes('coaching'),
      component: CoachingView,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.coaching',
        forRoles: [
          roles.PATIENT,
        ],
        userAccess: () => store.getters['auth/hasCoachingEnabled'],
      },
    },

    // URL: /change-email-success/:token
    {
      path: routes('change_email_success'),
      name: routes('change_email_success'),
      component: ChangeEmailSuccess,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.changeEmailSuccess',
      },
    },

    // URL: /change-password/:token
    {
      path: routes('change_password'),
      name: routes('change_password'),
      component: ChangePassword,
      meta: {
        title: 'general.titles.changePassword',
        viewClass: 'view-landing',
      },
    },

    // URL: /dashboard
    {
      path: routes('dashboard'),
      name: routes('dashboard'),
      component: DashboardView,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.dashboard',
        viewClass: 'view-dashboard',
      },
    },

    // URL: /data-storage-agreement
    {
      path: routes('data_storage_agreement'),
      name: routes('data_storage_agreement'),
      component: DataStorageAgreement,
      meta: {
        authRequired: true,
        title: 'general.titles.dataStorageAgreement',
        viewClass: 'view-data-storage-agreement',
      },
    },

    // URL: /deactivated-account
    {
      path: routes('deactivated_account'),
      name: routes('deactivated_account'),
      component: FeedbackAccount,
      meta: {
        title: 'general.titles.deactivatedAccount',
        viewClass: 'view-landing',
      },
    },

    // URL: /delete-account
    {
      path: routes('delete_account'),
      name: routes('delete_account'),
      component: DeleteAccount,
      meta: {
        title: 'general.titles.deleteAccount',
        viewClass: 'view-landing',
      },
    },

    // URL: /deleted-account
    {
      path: routes('deleted_account'),
      name: routes('deleted_account'),
      component: FeedbackAccount,
      meta: {
        title: 'general.titles.deletedAccount',
        viewClass: 'view-landing',
      },
    },

    // URL: /diary
    {
      path: routes('diary'),
      name: routes('diary'),
      component: DiaryView,
      meta: {
        title: 'general.titles.diary',
        authRequired: true,
        forRoles: [
          roles.PATIENT,
        ],
        migrationRequired: true,
        navigationBar: true,
      },
    },

    // URL: /error
    {
      path: routes('error'),
      name: routes('error'),
      component: PageGeneralError,
      meta: {
        title: 'general.titles.error',
        viewClass: 'view-landing',
      },
      props: (route) => ({
        errorText: route.params.errorText || undefined,
        redirectUrl: route.query.redirect_to,
      }),
    },

    // URL: /expired-token
    {
      path: routes('expired_token'),
      name: routes('expired_token'),
      component: ExpiredToken,
      meta: {
        title: 'general.titles.expiredToken',
        viewClass: 'view-landing',
      },
    },

    // URL: /forgot-password
    {
      path: routes('forgot_password'),
      name: routes('forgot_password'),
      component: ForgotPassword,
      meta: {
        title: 'general.titles.forgotPassword',
        viewClass: 'view-landing',
      },
    },

    // URL: /groups
    {
      path: routes('groups'),
      name: routes('groups'),
      component: GroupsView,
      meta: {
        authRequired: true,
        forRoles: [
          roles.PATIENT,
          roles.ORGANISATION_CARE_GIVER,
        ],
        migrationRequired: true,
        navigationBar: true,
        title: 'general.titles.groups',
      },
    },

    // URL: /
    {
      path: routes('home'),
      redirect: routes('login'),
    },

    // URL: /login
    {
      path: routes('login'),
      name: routes('login'),
      component: LoginView,
      meta: {
        title: 'general.titles.home',
        viewClass: 'view-landing',
      },
      props: (route) => ({
        hasExistingAccount: !!route.query.has_existing_account,
        organisationSlug: route.query.organisation_slug,
        organisationName: route.query.organisation_name,
        organisationLogo: route.query.organisation_logo,
        organisationText: route.query.organisation_text,
      }),
    },

    // URL: /login-with-token
    {
      path: routes('login_with_token'),
      name: routes('login_with_token'),
      component: LoginWithToken,
      meta: {
        title: 'general.titles.loginWithToken',
        viewClass: 'view-landing',
      },
      props: (route) => ({
        authCode: route.query.auth_code,
      }),
    },

    // URL: /monitors
    {
      path: routes('monitor'),
      name: routes('monitor'),
      component: MonitorView,
      meta: {
        authRequired: true,
        forRoles: [
          roles.PATIENT,
        ],
        key: 'monitors',
        navigationBar: true,
        title: 'general.titles.monitor',
      },
    },

    // URL: /organisation-care-plan-create
    {
      path: routes('organisation_care_plan_create'),
      name: routes('organisation_care_plan_create'),
      component: CarePlanCreate,
      canReuse: false,
      props: {
        isOrganisationAdmin: true,
      },
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_ADMIN,
        ],
        breadcrumb: {
          name: 'general.titles.administration',
          link: routes('care_plan_participants_admin'),
        },
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.carePlanCreate',
      },
    },

    // URL: /organisation/:organisationSlug/care-plan
    {
      path: routes('organisation_slug_care_plan_create'),
      name: routes('organisation_slug_care_plan_create'),
      component: CreateCarePlanUnderOrganisation,
      props: (route) => ({
        organisationSlug: route.params.organisationSlug,
        organisationName: route.query.organisation_name,
        treatmentSlug: route.query.treatment_slug,
      }),
      meta: {
        authRequired: true,
        title: 'general.titles.carePlanCreate',
        viewClass: 'view-landing view-landing__organisation-slug-care-plan-create',
      },
    },

    // URL: /403
    {
      path: routes('page_403'),
      name: routes('page_403'),
      component: PageRestricted,
      meta: {
        navigationBar: true,
        title: 'general.titles.403',
      },
    },

    // URL: /500
    {
      path: routes('page_500'),
      name: routes('page_500'),
      component: PageInternalError,
      meta: {
        navigationBar: true,
        title: 'general.titles.500',
      },
    },

    // URL: /patients/:patientId/add-assignment
    {
      path: routes('patient_add_assignment'),
      name: routes('patient_add_assignment'),
      component: CarePlanAdd,
      canReuse: false,
      props: (route) => ({
        patientId: parseInt(route.params.patientId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.addMoreToCarePlan',
      },
    },

    // URL: /patient-care-plans/:id
    {
      path: routes('patient_care_plan'),
      name: routes('patient_care_plan'),
      component: PatientCarePlans,
      props: (route) => ({
        id: parseInt(route.params.id, 10),
      }),
      meta: {
        action: 'view',
        authRequired: true,
        breadcrumb: { name: 'general.titles.carePlan', link: routes('patient_care_plan') },
        forRoles: [
          roles.PATIENT,
        ],
        navigationBar: true,
        resource: 'care_plans',
        title: 'general.titles.patientCarePlans',
      },
    },

    // URL: /patient-care-plans
    {
      path: routes('patient_care_plans'),
      name: routes('patient_care_plans'),
      component: PatientCarePlans,
      meta: {
        action: 'view',
        authRequired: true,
        forRoles: [
          roles.PATIENT,
        ],
        navigationBar: true,
        resource: 'care_plans',
        title: 'general.titles.patientCarePlans',
      },
    },

    // URL: /organisations/:organisationId/questionnaire-assignment/:questionnaireAssignmentId
    {
      path: routes('questionnaire_assignment_detail'),
      name: routes('questionnaire_assignment_detail'),
      component: QuestionnaireAssignment,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        questionnaireAssignmentId: parseInt(route.params.questionnaireAssignmentId, 10),
      }),
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.questionnaireAssignment',
      },
    },

    // URL: /activate
    {
      path: routes('questionnaire_informant_assignment'),
      name: routes('questionnaire_informant_assignment'),
      component: QuestionnaireInformant,
      props: (route) => ({
        questionnaireInformantAssignmentId: parseInt(
          route.params.questionnaireInformantAssignmentId,
          10,
        ),
      }),
      meta: {
        title: 'general.titles.questionnaireInformant',
        viewClass: 'view-questionnaire-informant',
      },
    },

    // URL: /organisations/:organisationId/questionnaires
    {
      path: routes('questionnaire_list'),
      name: routes('questionnaire_list'),
      component: QuestionnaireList,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        title: 'general.titles.questionnaireList',
      },
    },

    // URL: /register
    {
      path: routes('register'),
      name: routes('register'),
      component: RegisterView,
      meta: {
        title: 'general.titles.register',
        viewClass: 'view-landing',
      },
    },

    // URL: /register/:organisationSlug
    {
      path: routes('register_organisation_slug'),
      name: routes('register_organisation_slug'),
      component: RegisterView,
      meta: {
        title: 'general.titles.register',
        viewClass: 'view-landing',
      },
      props: (route) => ({
        organisationSlug: route.params.organisationSlug,
        treatmentSlug: route.query.treatment || route.query.treatment_slug,
      }),
    },

    // URL: /register-options
    {
      path: routes('register_options'),
      name: routes('register_options'),
      component: RegisterOptions,
      meta: {
        title: 'general.titles.register',
        viewClass: 'view-landing view-landing__register-options',
      },
    },

    // URL: /request-organisation
    {
      path: routes('request_organisation'),
      name: routes('request_organisation'),
      component: RequestOrganisation,
      meta: {
        title: 'general.titles.registerOrganisation',
        viewClass: 'view-landing',
      },
    },

    // URL: /security-code
    {
      path: routes('security_code'),
      name: routes('security_code'),
      component: SecurityCode,
      meta: {
        title: 'general.titles.securityCode',
        viewClass: 'view-security-code',
      },
    },

    // URL: /settings
    {
      path: routes('settings'),
      name: routes('settings'),
      component: SettingsView,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.settings',
      },
    },

    // URL: /sso
    {
      path: routes('sso_validation'),
      name: routes('sso_validation'),
      component: SsoValidation,
      meta: {
        title: 'general.titles.ssoValidation',
        viewClass: 'view-landing',
      },
      props: (route) => ({
        authCode: route.query.auth_code,
      }),
    },

    // URL: /organisations/:organisationId/treatment-assignment/:treatmentAssignmentId
    {
      path: routes('treatment_assignment_detail'),
      name: routes('treatment_assignment_detail'),
      component: TreatmentAssignmentDetail,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        treatmentAssignmentId: parseInt(route.params.treatmentAssignmentId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
          roles.PATIENT,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.treatmentList',
      },
    },

    // URL: /organisations/:organisationId/treatment-assignment/:treatmentAssignmentId/payment
    {
      path: routes('treatment_assignment_step_payment'),
      name: routes('treatment_assignment_step_payment'),
      component: TreatmentAssignmentStepPayment,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        treatmentAssignmentId: parseInt(route.params.treatmentAssignmentId, 10),
        stepId: parseInt(route.params.stepId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.PATIENT,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentPayment',
      },
    },

    // URL: /organisations/:organisationId/treatment-assignment
    // /:treatmentAssignmentId/payment-status
    {
      path: routes('treatment_assignment_step_payment_status'),
      name: routes('treatment_assignment_step_payment_status'),
      component: TreatmentAssignmentStepPaymentStatus,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        treatmentAssignmentId: parseInt(route.params.treatmentAssignmentId, 10),
        stepId: parseInt(route.params.stepId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.PATIENT,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentPayment',
        viewClass: 'view-treatment-assignment-step-payment-status',
      },
    },

    // URL: /organisations/:organisationId/treatment-assignment/:treatmentAssignmentId/step/:stepId
    {
      path: routes('treatment_assignment_step'),
      name: routes('treatment_assignment_step'),
      component: TreatmentAssignmentStep,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        stepId: parseInt(route.params.stepId, 10),
        treatmentAssignmentId: parseInt(route.params.treatmentAssignmentId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
          roles.PATIENT,
        ],
        navigationBar: true,
        switchOrganisationsNotAllow: true,
        title: 'general.titles.treatmentList',
      },
    },

    // URL: /organisations/:organisationId/treatments/:treatmentId
    {
      path: routes('treatment_detail'),
      name: routes('treatment_detail'),
      component: TreatmentDetail,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        treatmentId: parseInt(route.params.treatmentId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
          roles.PATIENT,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentDetail',
      },
    },

    // URL: /organisations/:organisationId/treatment/:treatmentId/information/:informationPageId
    {
      path: routes('treatment_introduction_step'),
      name: routes('treatment_introduction_step'),
      component: TreatmentInformation,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        treatmentId: parseInt(route.params.treatmentId, 10),
        informationPageId: parseInt(route.params.informationPageId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
          roles.PATIENT,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentInformation',
      },
    },

    // URL: /organisations/:organisationId/treatments
    {
      path: routes('treatment_list'),
      name: routes('treatment_list'),
      component: TreatmentList,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentList',
      },
    },

    // URL: /treatments-by-organisation
    {
      path: routes('treatment_by_organisation_list'),
      name: routes('treatment_by_organisation_list'),
      component: TreatmentListMultipleOrgs,
      meta: {
        authRequired: true,
        forRoles: [
          roles.PATIENT,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentList',
      },
    },

    // URL: /organisations/:organisationId/treatment/:treatmentId/step/:stepId
    {
      path: routes('treatment_step'),
      name: routes('treatment_step'),
      component: TreatmentStep,
      props: (route) => ({
        organisationId: parseInt(route.params.organisationId, 10),
        treatmentId: parseInt(route.params.treatmentId, 10),
        stepId: parseInt(route.params.stepId, 10),
      }),
      meta: {
        authRequired: true,
        forRoles: [
          roles.ORGANISATION_CARE_GIVER,
          roles.PATIENT,
        ],
        navigationBar: true,
        title: 'general.titles.treatmentStep',
      },
    },

    // URL: /social-environment-treatments/:socialEnvironmentTreatmentId
    {
      path: routes('social_environment_treatment'),
      name: routes('social_environment_treatment'),
      component: SocialEnvironmentTreatmentStep,
      props: (route) => ({
        socialEnvironmentTreatmentId: parseInt(route.params.socialEnvironmentTreatmentId, 10),
      }),
      meta: {
        authRequired: false,
        navigationBar: false,
        title: 'general.titles.treatmentStep',
      },
    },

    // URL: /user-name-information
    {
      path: routes('user_name_information'),
      component: UserNameInformation,
      meta: {
        authRequired: true,
        title: 'general.titles.userNameInformation',
        forRoles: [
          roles.PATIENT,
        ],
      },
    },

    // URL: /activate
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound,
      meta: {
        authRequired: true,
        navigationBar: true,
        title: 'general.titles.404',
      },
    },

    // URL: /two-factor-auth
    {
      path: routes('two_factor_auth'),
      name: routes('two_factor_auth'),
      component: TwoFactorBase,
      meta: {
        authRequired: true,
        title: 'general.titles.twoFactorAuthentication',
        viewClass: 'view-two-factor-auth',
      },
    },
  ],
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ ...savedPosition });
        }, 500);
      });
    }
    return { x: 0, y: 0 };
  },
});

export default router;
