<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="!isDisabled ? to || '' : ''"
    class="btn"
    :class="{
      btn__primary: !isSecondary,
      btn__secondary: isSecondary,
      btn__link: to,
      'btn--disabled': isDisabled,
      'btn--small': isSmall,
      [`btn--${variant}`]: variant,
    }"
    :type="to ? 'link' : type"
    :name="name"
    :tabindex="tabIndex"
    :disabled="isDisabled || isLoading"
    :value="value"
    @click="action()"
  >
    <transition
      v-if="isLoading"
      name="loader"
    >
      <span class="loader-wrapper">
        <LoaderComponent />
      </span>
    </transition>
    <transition
      v-if="!isLoading"
      name="slot"
    >
      <span class="btn__content">
        <slot />
      </span>
    </transition>
  </component>
</template>

<script src="./actionButton.js"></script>

<style lang="scss">
  .btn {
    display: inline-flex;
    min-width: 12rem;
    padding: .8rem 1.6rem;
    font-size: $font-size-small;
    border-radius: $radius-default;
    white-space: nowrap;
    text-transform: uppercase;
    transition: background-color 0.2s ease;
    position: relative;
    border-width: .1rem;
    justify-content: center;
    align-items: center;

    &--disabled.btn {
      opacity: .6;
      cursor: not-allowed;
      color: $neutral-100;
      border: .2rem solid $neutral-40;
      background-color: $neutral-20;
      &:hover {
        background-color: $secondary-20;
      }
    }

    &__content {
      display: flex;
      font-weight: $font-weight-semi-bold;
      span {
        font-weight: $font-weight-semi-bold;
      }
    }

    .loader {
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;

      .icon-component {
        display: flex;
      }
    }

    .slot-enter-active, .slot-leave-active,
    .loader-enter-active, .loader-leave-active {
      transition: opacity .5s;
    }

    .slot-enter, .slot-leave-to,
    .loader-enter, .loader-leave-to {
      opacity: 0;
    }

    &__primary {
      color: $neutral-0;
      background-color: $primary-60;
      transition: all 0.2s ease;
      -webkit-font-smoothing: antialiased;

      &:hover {
        background-color: $primary-80;
      }

      &.btn--secondary {
        background-color: $secondary-20;
        color: $secondary-80;

        &:hover {
          background-color: $secondary-40;
        }

        &:focus {
          color: $secondary-100;
        }
      }

      &.btn--info {
        background-color: $info-20;
        color: $info-80;

        &:hover {
          background-color: $info-40;
        }

        &:focus {
          color: $info-100;
        }
      }

      &.btn--warning {
        background-color: $warning-60;

        &:hover {
          background-color: $warning-80;
        }

        &:focus {
          color: $warning-100;
        }
      }

      &.btn--accent {
        background-color: $accent-60;

        &:hover {
          background-color: $accent-80;
        }

        &:focus {
          color: $accent-90;
        }
      }

      &.btn--success {
        background-color: $success-60;

        &:hover {
          background-color: $success-80;
        }

        &:focus {
          color: $success-100;
        }
      }

      &.btn--critical {
        background-color: $critical-20;

        &:hover {
          background-color: $critical-40;
        }

        &:focus {
          color: $critical-100;
        }
      }

      &.btn--neutral {
        background-color: $neutral-20;
        color: $secondary-80;

        &:hover {
          background-color: $neutral-40;
        }

        &:focus {
          color: $neutral-100;
        }
      }
    }

    &__secondary {
      position: relative;
      color: $primary-60;
      background-color: transparent;
      border: .1rem solid $primary-60;
      padding: 0.7rem 1.6rem;
      transition: all 0.2s ease;
      -webkit-font-smoothing: antialiased;

      &.btn--primary {
        &:hover {
          background-color: $primary-60;
          color: $neutral-0;
        }
      }

      &.btn--secondary {
        border: .1rem solid $secondary-60;
        color: $secondary-60;
        &:hover {
          background-color: $secondary-60;
          color: $secondary-10;
        }
      }

      &.btn--warning {
        color: $warning-60;
        border: .1rem solid $warning-60;

        &:hover {
          background-color: $warning-60;
          color: $neutral-0;
        }
      }

      &.btn--accent {
        color: $accent-80;
        border: .1rem solid $accent-80;

        &:hover {
          background-color: $accent-80;
          color: $neutral-0;
        }
      }

      &.btn--success {
        color: $success-60;
        border: .1rem solid $success-60;

        &:hover {
          background-color: $success-40;
        }
      }

      &.btn--critical {
        color: $critical-20;
        border: .1rem solid $critical-20;

        &:hover {
          background-color: $critical-10;
        }
      }

      &.btn--neutral {
        color: $neutral-60;
        border: .1rem solid $neutral-40;

        &:hover {
          background-color: $neutral-20;
        }
        &.btn--disabled {
          color: $neutral-60;
          border: .1rem solid $neutral-40;
          background-color: $neutral-20;
          opacity: .6;
        }
      }
    }

    &--small.btn {
      height: 3rem;
      border-width: .2rem;
      font-size: 1.4rem;
      text-transform: uppercase;

      .loader .icon-component {
        width: 1.5rem;
      }
    }
  }
</style>
